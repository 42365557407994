import React from 'react';

const BackendAccountingNavi = () => {
    return (
        <div className="bg-gray-400 text-white text-center">
            <nav className="container mx-auto flex justify-between items-center">
                <div className="hidden md:flex space-x-4">
                    <div className="relative ml-4">
                        <a href="/backend/accounting/incomming" className="block px-2 py-2 rounded hover:bg-gray-700">
                            Einnahmen
                        </a>
                    </div>
                    <div className="relative">
                        <a href="/backend/accounting/outgoing" className="block px-2 py-2 rounded hover:bg-gray-700">
                            Ausgaben
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default BackendAccountingNavi;
