import React, { useEffect, useState } from 'react';
import BackendHeader from '../../../Website/Backend/BackendHeader';
import BackendFooter from '../../../Website/Backend/BackendFooter';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getUrl } from '../../../Website/Config';
import TableUtils from '../../../Website/Table/TableUtils';

const BackendCustomerEdit = () => {
    const params = useParams();
    const [message] = useState('');
    const [error] = useState(false);
    const [customer, setCustomer] = useState({
        id: '',
        customerNr: '',
        email: '',
        forename: '',
        lastname: '',
        street: '',
        number: '',
        zip: '',
        city: '',
    });

    const [offers, setOffers] = useState([]);

    useEffect(() => {
        const fetchCustomerData = async () => {
            try {
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                const response = await axios.get(getUrl() + 'customer/single/' + params.customerid, axiosConfig);
                setCustomer({
                    id: response.data.id,
                    customerNr: response.data.customer_number,
                    email: response.data.email,
                    forename: response.data.forename,
                    lastname: response.data.lastname,
                    street: response.data.street,
                    number: response.data.number,
                    zip: response.data.zip,
                    city: response.data.city,
                });
            } catch (error) {
                console.error('Error fetching messages', error);
            }
        };

        fetchCustomerData();
    }, [params.customerid]);

    useEffect(() => {
        const fetchOfferData = async () => {
            try {
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                const listOffers = await axios.get(getUrl() + 'offer/' + params.customerid + '/all', axiosConfig);
                let listData = [];
                if (null !== listOffers.data) {
                    listData = TableUtils.prepareTableRowDataOffer(listOffers.data, params.customerid);
                }
                setOffers(listData);
            } catch (error) {
                console.error('Error fetching messages', error);
            }
        };

        fetchOfferData();
    }, [params.customerid]);

    return (
        <main className="md:pt-1 pb-20">
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white border-2">
                {<BackendHeader />}

                <section
                    id="showcase-laser"
                    className="bg-cover bg-center h-24 flex items-center justify-center text-white bg-[#3f888f]"
                >
                    <div className="w-full">
                        <h1 className="text-4xl font-bold ml-5">
                            {customer.forename} {customer.lastname}
                        </h1>
                        <h3 className="text-gray-200 ml-5">
                            {customer.customerNr} - {customer.email}
                        </h3>
                    </div>
                </section>

                <section>
                    <div className="border-b border-gray-400">
                        <div className="flex justify-between items-center w-full py-2 px-5">
                            <h1 className="text-lg font-bold">offene Angebote</h1>
                            <div>
                                <a href={'/backend/offer/create/' + params.customerid} className="text-xs">
                                    neues Angebot {'>>'}
                                </a>
                            </div>
                        </div>
                        <div>
                            <div>
                                <section>
                                    <div className="px-5 pb-5">
                                        {offers.length !== 0 ? (
                                            <table className="w-full">
                                                <thead>
                                                    <tr>
                                                        <th className="w-1/6 border border-gray-400 text-xs">Datum</th>
                                                        <th className="w-1/6 border border-gray-400 text-xs">Nummer</th>
                                                        <th className="w-1/6 border border-gray-400 text-xs">
                                                            Beschreibung
                                                        </th>
                                                        <th className="w-1/6 border border-gray-400 text-xs">
                                                            interne Bemerkung
                                                        </th>
                                                        <th className="w-1/6 border border-gray-400 text-xs">
                                                            Aktionen
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>{offers}</tbody>
                                            </table>
                                        ) : (
                                            <p className="text-center">Keine offenen Angebote vorhanden</p>
                                        )}
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="p-5 bg-white">
                        <section>
                            <div className="pb-5">
                                <div className="w-full p-2">
                                    <section className="p-8">
                                        <div className="p-5 bg-white">
                                            <form id="new-article">
                                                <div className="pb-5">
                                                    <div className="w-full p-2">Fields</div>
                                                    <div className="mb-4 justify-end w-full flex">
                                                        Button
                                                        {/*<Button label="Speichern" onClick={handleSubmit}/>*/}
                                                    </div>
                                                </div>
                                            </form>
                                            {error && message !== '' ? (
                                                <p className="mt-4 text-center text-red-500 text-xl p-5">{message}</p>
                                            ) : (
                                                <p className="mt-4 text-center text-green-500 text-xl p-5">{message}</p>
                                            )}
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                {<BackendFooter />}
            </div>
        </main>
    );
};

export default BackendCustomerEdit;
