import React, { useState } from 'react';
import CrudUtils from '../CRUD/CrudUtils';
import moment from 'moment';
import ListUtils from '../../../Website/List/ListUtils';
import BackendHeader from '../../../Website/Backend/BackendHeader';
import BackendFooter from '../../../Website/Backend/BackendFooter';
import { useParams } from 'react-router-dom';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
// @ts-ignore
import Session from 'react-session-api';

const BackendOfferMailTemplate = () => {
    const params = useParams();
    const [offerNumber] = useState(params.offernumber);
    let [totalPrice] = useState(0);
    const [offer, setOffer] = useState({
        offer_id: '',
        offer_number: '',
        offer_date: '',
        customer_salutation: '',
        customer_name: '',
        customer_street: '',
        customer_zip: '',
        customer_city: '',
        customer_number: '',
        customer_email: '',
        customer_id: '',
        descriptionCustomer: '',
        internDescription: '',
        offerPositions: [
            {
                id: '',
                amount: '',
                article: '',
                price: '',
            },
        ],
    });
    const [email, setEmail] = useState({
        stored: '',
        dispatched: '',
    });

    let euro = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
    });

    // const userData = Session.get('userSession');
    // console.log(userData);

    const setOfferData = async () => {
        try {
            return await CrudUtils.getData('offer-created/' + offerNumber);
        } catch (error) {
            console.error(error);
        }
    };

    setOfferData().then((offerData) => {
        if (offerData !== undefined) {
            const offerDataFromDB = offerData['offer'];

            if (offer.offer_id === '') {
                setOffer({
                    offer_id: offerDataFromDB['id'],
                    offer_number: offerDataFromDB['offer_number'],
                    offer_date: moment(offerDataFromDB['offer_date']).format('DD.MM.YYYY'),
                    customer_salutation: offerDataFromDB['salutation'],
                    customer_name: offerDataFromDB['forename'] + ' ' + offerDataFromDB['lastname'],
                    customer_street: offerDataFromDB['street'] + ' ' + offerDataFromDB['address_number'],
                    customer_zip: offerDataFromDB['zip'],
                    customer_city: offerDataFromDB['city'],
                    customer_number: offerDataFromDB['customer_number'],
                    customer_email: offerDataFromDB['email'],
                    customer_id: offerDataFromDB['customer_id'],
                    descriptionCustomer: null !== offerDataFromDB['description'] ? offerDataFromDB['description'] : '',
                    internDescription:
                        null !== offerDataFromDB['intern_description'] ? offerDataFromDB['intern_description'] : '',
                    offerPositions: offerData['offer_position'].map((position: any) => {
                        return {
                            id: position.id,
                            amount: position.amount,
                            article: position.article,
                            price: position.price,
                        };
                    }),
                });
            }
        }
    });

    const setMailData = async () => {
        try {
            return await CrudUtils.getData('email/' + offerNumber);
        } catch (error) {
            console.error(error);
        }
    };

    setMailData().then((mailData) => {
        if (email.stored === '' && mailData !== null) {
            const tmpStored = mailData.created !== '' ? moment(mailData.created).format('DD.MM.YYYY HH:mm') : null;
            const tmpDispatched = mailData.sended !== '' ? moment(mailData.sended).format('DD.MM.YYYY HH:mm') : null;

            setEmail({
                // @ts-ignore
                stored: tmpStored,
                // @ts-ignore
                dispatched: tmpDispatched,
            });
        }
    });

    const generatePDF = () => {
        htmlToImage
            .toPng(document.getElementById('offerPreview') as HTMLElement, { quality: 0.95 })
            .then(function (dataUrl) {
                // var link = document.createElement('a');
                // link.download = 'my-image-name.jpeg';
                const pdf = new jsPDF();
                const imgProps = pdf.getImageProperties(dataUrl);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
                // const pdfBase64String = pdf.output('datauristring');
                // const pdfParts = pdfBase64String.split('base64,');

                try {
                    const emailData = {
                        content: 'Email content - Angebot',
                        document_nr: offer.offer_number,
                        email_address: offer.customer_email,
                    };

                    CrudUtils.postEmailData(emailData, 'sendmail').then((r) => {
                        console.log('r', r);
                    });
                } catch (error) {
                    console.error('Error fetching messages', error);
                }
            });
    };

    // @ts-ignore
    return (
        <main className="md:pt-1 pb-20">
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white border-2">
                {<BackendHeader />}
                <section className="bg-cover bg-center h-24 flex items-center justify-center text-white bg-[#3f888f]">
                    <div className="grid grid-cols-2 gap-2 container">
                        <div className="w-full content-start">
                            <h1 className="text-4xl font-bold ml-5">{offer.customer_name}</h1>
                            <h3 className="text-gray-200 ml-5">
                                {offer.customer_number} - {offer.customer_email}
                            </h3>
                        </div>
                        <div className="w-full text-right pr-4 content-end">
                            <a href={'/backend/customer/edit/' + offer.customer_id} className="text-xs">
                                {'<<'} Back
                            </a>
                            <span className="px-2">|</span>
                            {email.stored === '' ? (
                                <button className="text-xs" onClick={generatePDF}>
                                    jetzt per Mail verschicken {'>>'}
                                </button>
                            ) : (
                                <button className="text-xs" disabled>
                                    Mail wurde am {email.stored} übertragen
                                    {email.dispatched !== null ? ' und am ' + email.dispatched + ' versendet' : ''}
                                </button>
                            )}
                        </div>
                    </div>
                </section>
                <div className="flex flex-row justify-center bg-white py-4">
                    <div className="w-[210mm] h-[297mm] border border-gray-400">
                        <div id="offerPreview" className="w-[210mm] h-[297mm] p-16">
                            <div className="border-t-4 border-[#487ba2] w-full mt-8">
                                <div className="text-[#487ba2] font-bold text-xl">
                                    LAGOCKI - REFINEMENT, TEXTILE & PRINT
                                </div>
                            </div>
                            <div className="flex flex-row mt-14">
                                <div className="w-[70mm]">
                                    <div className="text-[30px] font-extralight text-[#487ba2]">ANGEBOT</div>
                                    <div className="mt-6 text-refinement-blue text-xs">(02598)-83 19 771</div>
                                    <div className="text-refinement-blue text-xs">info@lagocki.de</div>
                                    <div className="mt-6 text-xs">Auf der Horst 32</div>
                                    <div className="text-xs">48308 Senden</div>
                                </div>
                                <div className="pt-4 w-full">
                                    <div className="text-xs">{offer.customer_salutation}</div>
                                    <div className="text-xs">{offer.customer_name}</div>
                                    <div className="text-xs">{offer.customer_street}</div>
                                    <div className="mt-6 text-xs">
                                        {offer.customer_zip} {offer.customer_city}
                                    </div>
                                    <div className="mt-6 text-xs">Nr.: {offer.offer_number}</div>
                                    <div className="text-xs">Datum: {offer.offer_date}</div>
                                    <div className="mt-6 text-xs">Anmerkungen: {offer.descriptionCustomer}</div>

                                    <div className="mt-8 w-full flex flex-row">
                                        <div className="w-full px-2 py-1 m-0.5 text-[#ffffff] bg-[#487ba2] border border-dotted border-refinement-black font-extralight text-xs">
                                            Artikel
                                        </div>
                                        <div className="w-2/12 px-2 py-1 m-0.5 text-[#ffffff] bg-[#487ba2] border border-dotted border-refinement-black font-extralight text-xs">
                                            Stk
                                        </div>
                                        <div className="w-3/12 px-2 py-1 m-0.5 text-[#ffffff] bg-[#487ba2] border border-dotted border-refinement-black font-extralight text-xs">
                                            Preis/Stk
                                        </div>
                                        <div className="w-4/12 px-2 py-1 m-0.5 text-[#ffffff] bg-[#487ba2] border border-dotted border-refinement-black font-extralight text-xs">
                                            Gesamt
                                        </div>
                                    </div>

                                    {offer.offerPositions.map((position) => {
                                        let posTotalPrice = parseFloat(position.amount) * parseFloat(position.price);
                                        totalPrice += posTotalPrice;
                                        return (
                                            <div
                                                className="w-full flex flex-row border-b border-dotted border-refinement-blue"
                                                key={ListUtils.makeid(10)}
                                            >
                                                <div className="w-full px-2 py-1 m-0.5 font-extralight text-xs">
                                                    {position.article}
                                                </div>
                                                <div className="w-2/12 px-2 py-1 m-0.5 font-extralight text-right text-xs">
                                                    {position.amount}
                                                </div>
                                                <div className="w-3/12 px-2 py-1 m-0.5 font-extralight text-right text-xs">
                                                    {
                                                        // @ts-ignore
                                                        euro.format(position.price)
                                                    }
                                                </div>
                                                <div className="w-4/12 px-2 py-1 m-0.5 font-extralight text-right text-xs">
                                                    {euro.format(posTotalPrice)}
                                                </div>
                                            </div>
                                        );
                                    })}

                                    <div className="w-full flex flex-row">
                                        <div className="w-full px-2 py-1 m-0.5 font-extralight text-right text-xs">
                                            Zwischensumme
                                        </div>
                                        <div className="w-3/12 px-2 py-1 m-0.5 font-extralight">&nbsp;</div>
                                        <div className="w-4/12 px-2 py-1 m-0.5 font-extralight text-right text-xs">
                                            {euro.format(totalPrice)}
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-row border-b border-refinement-black">
                                        <div className="w-full px-2 py-1 m-0.5 font-extralight text-right text-xs">
                                            Mwst
                                        </div>
                                        <div className="w-3/12 px-2 py-1 m-0.5 font-extralight text-right text-xs">
                                            0.00 %
                                        </div>
                                        <div className="w-4/12 px-2 py-1 m-0.5 font-extralight text-right text-xs">
                                            0,00 €
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-row border-b border-refinement-black border-double">
                                        <div className="w-full px-2 py-1 m-0.5 font-bold text-right text-xs">
                                            Gesamt
                                        </div>
                                        <div className="w-3/12 px-2 py-1 m-0.5 font-extralight">&nbsp;</div>
                                        <div className="w-4/12 px-2 py-1 m-0.5 font-bold text-right text-xs">
                                            {euro.format(totalPrice)}
                                        </div>
                                    </div>

                                    <div className="">
                                        <p className="mt-14 font-extralight text-xs">
                                            Bei Auftragsfreigabe benötigen wir ca. 10 Werktage zur Fertigstellung.
                                        </p>
                                        <p className="mt-4 font-extralight text-xs">
                                            Als Kleinunternehmer im Sinne von § 19 Abs.1 UStG wird keine Umsatzsteuer
                                            berechnet.
                                        </p>
                                        <p className="mt-4 font-extralight text-xs">
                                            Bei weiteren Fragen oder speziellen Wünschen stehen wir Dir gerne zur
                                            Verfügung. Wir freuen uns auf eine erfolgreiche Zusammenarbeit!
                                        </p>
                                        <p className="mt-4 font-extralight text-xs">Mit freundlichen Grüßen</p>
                                        <p className="mt-4 font-extralight text-xs">Andreas Lagocki</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {<BackendFooter />}
            </div>
        </main>
    );
};

export default BackendOfferMailTemplate;
