import React, { useState } from 'react';

import BackendHeader from '../../../Website/Backend/BackendHeader';
import BackendFooter from '../../../Website/Backend/BackendFooter';
import BackendAccountingNavi from './BackendAccountingNavi';
import Button from '../../../Website/Form/Button';
import InputText from '../../../Website/Form/InputText';

const BackendAccountingIncomming = () => {
    const [message, setMessage] = useState('');
    const [incomming, setComming] = useState({
        account: '',
        amount: '',
        price: '',
    });
    const handleFieldChangeIncommintPosition = (inputFieldId: any, inputFieldValue: any) => {
        // @ts-ignore
        setComming({ ...incomming, [inputFieldId]: inputFieldValue });
    };

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setMessage('Position gespeichert');
    };

    return (
        <main className="md:pt-1 pb-20">
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white border-2">
                {<BackendHeader />}

                <section className="bg-cover bg-center h-24 flex items-center justify-center text-white bg-[#3f888f]">
                    <div className="grid grid-cols-2 gap-2 container">
                        <div className="w-full content-start">
                            <h1 className="text-4xl font-bold ml-5">Buchhaltung</h1>
                            <h3 className="text-gray-200 ml-5">Einnahmen erfassen</h3>
                        </div>
                        <div className="w-full text-right pr-4 content-end">&nbsp;</div>
                    </div>
                </section>

                {<BackendAccountingNavi />}

                <section className="p-8">
                    <div className="p-5 bg-white">
                        <div className="pb-5">
                            <div className="w-full p-2">
                                <form id="contact-form">
                                    <div className="grid grid-cols-1 gap-1">
                                        <div className="mb-4">
                                            <InputText
                                                id="amount"
                                                label=""
                                                error=""
                                                show={false}
                                                onChange={handleFieldChangeIncommintPosition}
                                                value={incomming.amount}
                                            />
                                        </div>

                                        <div className="mb-4">&nbsp;</div>
                                    </div>

                                    <div className="grid grid-cols-1 gap-1">
                                        <div className="mb-4 w-full content-end">
                                            <Button label="Position speichern" onClick={handleSubmit} />
                                        </div>
                                    </div>
                                </form>
                                {message}
                            </div>
                        </div>
                    </div>
                </section>

                {<BackendFooter />}
            </div>
        </main>
    );
};

export default BackendAccountingIncomming;
