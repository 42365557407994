// @ts-ignore
import React from 'react';

import CrudUtils from './CrudUtils';

class StatusDocument {
    static SetStatus = async (url: string) => {
        try {
            return await CrudUtils.setDocumentStatus(url);
        } catch (error) {
            console.error('Error fetching messages', error);
        }
    };
}

export default StatusDocument;
