import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import StatusDocument from '../CRUD/DeleteDocument';

const BackendOfferToOrder = () => {
    const params = useParams();
    const [offerNumber] = useState(params.offernumber);
    const [customerid] = useState(params.customerid);
    const navigate = useNavigate();

    const deleteOffer = async () => {
        try {
            return await StatusDocument.SetStatus('offer-to-order/' + offerNumber);
        } catch (error) {
            console.error(error);
        }
    };

    deleteOffer().then((result) => {
        console.log('result', result);
        navigate('/backend/customer/edit/' + customerid);
    });

    return null;
};

export default BackendOfferToOrder;
